.has-details {
  position: relative;
}

.details {
  position: absolute;
  transform: translateY(10%) scale(0) translateX(50%);
  transition: transform 0.1s ease-in;
  transform-origin: left;
  display: inline;
  width: 3.563rem;
  height: 7.125rem;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  z-index: 20;
  padding: 0.5rem 0 0.5rem 0.5rem;
  border-radius: 5px;
  height: 7.125rem;
  width: 8.5rem;
  font-size: 12px;
  font-style: italic;
  paint-order: stroke fill;
  align-items: left;
  text-align: left;
}

.details::before {
  content: "";
  position: absolute;
  top: -12px;
  left: 10%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid rgba(0, 0, 0, 0.8);
}

.has-details:hover .details {
  transform: translateY(10%) scale(1);
}

.has-details:hover span {
  transform: translateY(70%) scale(1);
}
